import { LIST as DIFFICULTIES_LIST } from "../../../../../constants/difficulties";

import { Stack, Typography } from "@mui/material";

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import CustomPaper from "../../../../../components/CustomPaper";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const CourseTrendsTab: React.FunctionComponent<{}> = () => {
  return (
    <Stack>
      <Typography variant="h3">Knowledge level in different topics</Typography>
      <CustomPaper sx={{ padding: 2 }}>
        <Radar
          style={{ maxHeight: 600 }}
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: "left",
              },
              tooltip: {
                callbacks: {
                  label(tooltipItem) {
                    return `${tooltipItem.label}: ${tooltipItem.formattedValue}%`;
                  },
                },
              },
            },
          }}
          data={{
            labels: [
              "Sistēmu arhitektūra",
              "Datu nesēji",
              "Izspiedējvīrusi",
              "Sociālā inženierija",
              "Ļaunatūra",
              "Termini un definīcijas",
              "Datu aizsardzība",
            ],
            datasets: DIFFICULTIES_LIST.map((difficulty, index) => {
              const data = [
                [60, 60, 40, 20, 10, 10, 60],
                [100, 10, 0, 20, 50, 10, 60],
                [20, 20, 10, 50, 20, 60, 60],
              ];

              return {
                label: difficulty.name,
                data: data[index],
                borderColor: `rgb(${difficulty.color[0]}, ${difficulty.color[1]}, ${difficulty.color[2]})`,
                backgroundColor: `rgba(${difficulty.color[0]}, ${difficulty.color[1]}, ${difficulty.color[2]}, 0.15)`,
              };
            }),
          }}
        />
      </CustomPaper>
    </Stack>
  );
};

export default CourseTrendsTab;
