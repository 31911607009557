import { useCallback, useEffect, useRef, useState } from "react";

import { Link as RouterLink, useParams } from "react-router-dom";

import {
  Grid,
  Stack,
  Typography,
  Tab,
  Breadcrumbs,
  Link,
  Popover,
  Autocomplete,
  TextField,
} from "@mui/material";

import Helper from "../../../../../components/Helper";
import { EmployeesAnalyticsHelper } from "../../../../../constants/helpers";

import { TabContext, TabList, TabPanel } from "@mui/lab";

import CourseTrendsTab from "./TrendsTab";
import Chevron from "../../../../../components/Icons/Chevron";
import CustomPaper from "../../../../../components/CustomPaper";

import ArchDiagram from "../../../../../components/Arch";
import axios from "axios";

import xlsx, { IJsonSheet } from "json-as-xlsx";

import { DIFFICULTY_LIST } from "../../../../../constants/difficulties";
import { CourseTrainingType } from "../../../../../constants/courseTrainingType";

import {
  ICourseAnalytics,
  IEmployeeCourseLessonsAnalytics,
  IEmployeesCourseLessons,
  IEmployeesKnowledgeFilters,
} from "../../../../../types/analytics";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import GridToolbar from "../../../../../components/Grid/Toolbar";
import CourseDetails from "../../../../../components/CourseDetails";
import Check from "../../../../../components/Icons/Check";
import Close from "../../../../../components/Icons/Close";
import moment from "moment";

interface IQueryOptions {
  courseTrainingType?: CourseTrainingType;
  questionCategoryId?: number;
  topic?: string;
  difficulty?: number;
}

const CourseAnalytics: React.FunctionComponent<{}> = () => {
  const { employeeId, courseTrainingType, courseId } = useParams<{
    employeeId: string;
    courseTrainingType: string;
    courseId: string;
  }>();

  const [courseDetails, setCourseDetails] = useState<ICourseAnalytics>();

  const [tab, setTab] = useState("1");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IEmployeeCourseLessonsAnalytics>();
  const [filterData, setFilterData] = useState<IEmployeesKnowledgeFilters>();

  const gridRef = useRef(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [queryOptions, setQueryOptions] = useState<IQueryOptions>({});

  const handleXLSXExport = useCallback(() => {
    if (!data) {
      return;
    }

    const exportData: IJsonSheet[] = [
      {
        sheet: "Employees",
        columns: [
          {
            label: "Content",
            value: "question",
          },
          {
            label: "Topic",
            value: "topic",
          },
          {
            label: "Difficulty",
            value: "difficulty",
          },
          {
            label: "Time sent",
            value: "timeSent",
          },
          {
            label: "Time answered",
            value: "timeAnswered",
          },
          {
            label: "Answer given",
            value: "answerGiven",
          },
          {
            label: "Expected answer",
            value: "answerCorrect",
          },
        ],
        // @ts-ignore
        content: data.employeeKnowledge,
      },
    ];

    xlsx(exportData, {
      fileName: `${data.displayName.replace(
        " ",
        "-"
      )}-${data.courseName.replace(" ", "-")}-analytics`,
    });
  }, [data]);

  useEffect(() => {
    axios
      .get<IEmployeesKnowledgeFilters>("/Alytics/GetEmployeeKnowledgeFilter")
      .then((res) => {
        setFilterData(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get<IEmployeeCourseLessonsAnalytics>("/Alytics/GetEmployeeKnowledge", {
        params: {
          userId: employeeId,
          courseTrainingType: courseTrainingType,
          courseId: courseId,
          ...queryOptions,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [queryOptions]);

  const columns: GridColDef<IEmployeesCourseLessons>[] = [
    {
      field: "question",
      headerName: "Content",
      sortable: false,
      minWidth: 200,
      flex: 2,
    },
    {
      field: "topic",
      headerName: "Topic",
      sortable: false,
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return params.value || "-";
      },
    },
    {
      field: "difficulty",
      headerName: "Difficulty",
      sortable: false,
      minWidth: 140,
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        }

        return DIFFICULTY_LIST[params.row.difficulty].name;
      },
    },
    {
      field: "timeSent",
      headerName: "Time sent",
      sortable: false,
      minWidth: 200,
      renderCell: (params) => {
        return moment(params.value).format("L LT");
      },
    },
    {
      field: "timeAnswered",
      headerName: "Time completed",
      sortable: false,
      minWidth: 220,
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        }
        return moment(params.value).format("L LT");
      },
    },
    {
      field: "answerGiven",
      headerName: "Answer given",
      sortable: false,
      minWidth: 200,
      renderCell: (params) => {
        return params.value || "-";
      },
    },
    {
      field: "answerCorrect",
      headerName: "Expected answer",
      sortable: false,
      minWidth: 240,
      renderCell: (params) => {
        if (params.value === undefined) {
          return "-";
        }

        if (params.value) {
          return <Check color="#2e7d32" />;
        }
        return <Close color="#d32f2f" />;
      },
    },
  ];

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Stack direction="row" alignItems="center">
            <Typography component="h1" variant="h2">
              Employee analytics
            </Typography>
            <Helper title={<EmployeesAnalyticsHelper />} />
          </Stack>
        </Grid>
      </Grid>
      <Breadcrumbs
        separator={
          <Chevron
            width={20}
            height={20}
            style={{
              transform: "rotate(90deg)",
            }}
          />
        }
      >
        <Link
          component={RouterLink}
          to={"/analytics/employees"}
          underline="none"
          color="inherit"
        >
          Employees
        </Link>
        <Link
          component={RouterLink}
          to={`/analytics/employees/${employeeId}`}
          underline="none"
          color="inherit"
        >
          {data?.displayName}
        </Link>

        <Typography variant="inherit">{data?.courseName}</Typography>
      </Breadcrumbs>

      <TabContext value={tab}>
        {parseInt(courseTrainingType as string) ===
          CourseTrainingType.Instant && (
          <Stack direction="row">
            <TabList onChange={handleTabChange}>
              <Tab label="Lessons" value="1" />
              <Tab label="Trends" value="2" />
            </TabList>
          </Stack>
        )}

        <TabPanel value="1">
          <Stack spacing={2}>
            <div>
              <Grid
                container
                spacing={4}
                style={{
                  display: "flex",
                }}
              >
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="h3">Training details</Typography>
                    <CustomPaper sx={{ padding: 2 }}>
                      <CourseDetails
                        courseId={parseInt(courseId as string)}
                        courseTrainingType={
                          courseTrainingType as unknown as CourseTrainingType
                        }
                      />
                    </CustomPaper>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                  }}
                >
                  <Stack flex={1}>
                    <Typography variant="h3">
                      Successfully completed lessons
                    </Typography>
                    <CustomPaper
                      sx={{
                        position: "relative",
                        minHeight: 200,

                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ArchDiagram
                        primaryText="12/14"
                        color={
                          courseDetails?.courseTrainingType ===
                          CourseTrainingType.Regular
                            ? "default"
                            : false
                            ? // courseDetails?.lessonsCorrect  >= courseDetails?.correctAnswersCriteriaCount
                              "success"
                            : "error"
                        }
                        secondaryText={
                          courseDetails?.courseTrainingType !==
                          CourseTrainingType.Regular
                            ? courseDetails?.courseTrainingType ===
                              CourseTrainingType.Mandatory
                              ? `Goal: ${courseDetails?.correctAnswersCriteria}%`
                              : `Goal: ${courseDetails?.correctAnswersCriteriaCount}/${courseDetails?.lessonCount}`
                            : undefined
                        }
                        completion={60}
                        // completion={
                        //   (courseDetails?.lessonsCorrect /
                        //     courseDetails?.lessonsAnswered) *
                        //   100
                        // }
                      />
                    </CustomPaper>
                  </Stack>
                </Grid>
              </Grid>
            </div>

            <Stack>
              <Typography variant="h3">Lessons</Typography>
              <DataGrid
                ref={gridRef}
                disableColumnFilter
                getRowId={(row) => row.quizId}
                rows={data?.employeeKnowledge || []}
                rowCount={3}
                components={{
                  Toolbar: () => {
                    return (
                      <GridToolbar
                        filterCount={
                          Object.values(queryOptions).filter((item) => item)
                            .length
                        }
                        toggleFilter={() => setIsFilterOpen(true)}
                        onExportXLSX={handleXLSXExport}
                      />
                    );
                  },
                }}
                columns={columns}
                loading={isLoading}
                autoHeight
                disableRowSelectionOnClick
                disableColumnMenu
                pageSizeOptions={[100]}
              />
            </Stack>
          </Stack>

          <Popover
            open={isFilterOpen}
            anchorEl={gridRef.current}
            onClose={() => setIsFilterOpen(false)}
            style={{
              marginTop: 40,
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Stack
              spacing={2}
              sx={{ padding: 2, width: 400, maxWidth: "100%" }}
            >
              <Typography variant="h6">Filters</Typography>
              <Autocomplete
                options={filterData ? filterData.contentList : []}
                value={
                  filterData
                    ? filterData.contentList.find(
                        (item) => item.id === queryOptions.questionCategoryId
                      )
                    : null
                }
                getOptionLabel={(option) => option.contentName}
                renderInput={(params) => (
                  <TextField {...params} label="Content" />
                )}
                onChange={(e, value) => {
                  setQueryOptions((prev) => ({
                    ...prev,
                    contentId: value ? value.id : undefined,
                  }));
                }}
              />
            </Stack>
          </Popover>
        </TabPanel>
        <TabPanel value="2">
          <CourseTrendsTab />
        </TabPanel>
      </TabContext>
    </Stack>
  );
};

export default CourseAnalytics;
