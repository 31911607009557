import { useCallback, useEffect, useRef, useState } from "react";

import axios from "axios";

import { Link as RouterLink } from "react-router-dom";

import {
  Autocomplete,
  Grid,
  Link,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  IEmployeesAnalyticsFilters,
  IEmployeesAnalytics,
  IEmployeesAnalyticsEmployee,
} from "../../../../types/analytics";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { renderTooltipCell } from "../../../../helpers/grid";

import Helper from "../../../../components/Helper";
import { EmployeesAnalyticsHelper } from "../../../../constants/helpers";
import GridToolbar from "../../../../components/Grid/Toolbar";
import xlsx, { IJsonSheet } from "json-as-xlsx";

interface IQueryOptions {
  userId?: number;
}

const EmployeesAnalytics: React.FunctionComponent<{}> = () => {
  const gridRef = useRef(null);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filterData, setFilterData] = useState<IEmployeesAnalyticsFilters>();
  const [queryOptions, setQueryOptions] = useState<IQueryOptions>({});

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IEmployeesAnalytics>();

  const handleXLSXExport = useCallback(() => {
    if (!data) {
      return;
    }

    const exportData: IJsonSheet[] = [
      {
        sheet: "Employees",
        columns: [
          {
            label: "Employee",
            value: "displayName",
          },
          {
            label: "Teams",
            value: "teams",
          },
          {
            label: "Active courses",
            value: "activeCourses",
          },
          {
            label: "Sent lessons",
            value: "lessonsSent",
          },
          {
            label: "Completed lessons",
            value: "completedLessons",
          },
          {
            label: "Completed lessons %",
            value: "completedLessonsPercent",
          },
          {
            label: "Expected answers",
            value: "correctAnswers",
          },
          {
            label: "Expected answers %",
            value: "correctAnswersPercent",
          },
        ],
        // @ts-ignore
        content: data.employeeList,
      },
    ];

    xlsx(exportData, {
      fileName: "employees-analytics",
    });
  }, [data]);

  useEffect(() => {
    axios
      .get<IEmployeesAnalyticsFilters>("/Alytics/GetEmployeeFilter")
      .then((res) => {
        setFilterData(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get<IEmployeesAnalytics>("/Alytics/GetEmployeesAnalytics", {
        params: {
          ...queryOptions,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [queryOptions]);

  const columns: GridColDef<IEmployeesAnalyticsEmployee>[] = [
    {
      field: "displayName",
      headerName: "Employee",
      sortable: false,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Link
            to={`/analytics/employees/${params.row.userId}`}
            component={RouterLink}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "teams",
      headerName: "Teams",
      sortable: false,
      minWidth: 200,
    },
    {
      field: "activeCourses",
      headerName: "Active courses",
      sortable: false,
      minWidth: 200,
    },
    {
      field: "lessonsSent",
      headerName: "Sent lessons",
      sortable: false,
      minWidth: 200,
    },
    {
      field: "completedLessons",
      headerName: "Completed lessons",
      sortable: false,
      minWidth: 240,
      renderCell: (params) => {
        return (
          <Typography>
            {params.value} ({params.row.completedLessonsPercent}%)
          </Typography>
        );
      },
    },
    {
      field: "correctAnswers",
      headerName: "Expected answers",
      sortable: false,
      minWidth: 240,
      renderCell: (params) => {
        return (
          <Typography>
            {params.value} ({params.row.correctAnswersPercent}%)
          </Typography>
        );
      },
    },
  ];

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Stack direction="row" alignItems="center">
            <Typography component="h1" variant="h2">
              Employee analytics
            </Typography>
            <Helper title={<EmployeesAnalyticsHelper />} />
          </Stack>
        </Grid>
      </Grid>

      <DataGrid
        ref={gridRef}
        disableColumnFilter
        getRowId={(row) => row.userId}
        rows={data?.employeeList || []}
        rowCount={data?.numberOfEntries}
        components={{
          Toolbar: () => {
            return (
              <GridToolbar
                filterCount={
                  Object.values(queryOptions).filter((item) => item).length
                }
                toggleFilter={() => setIsFilterOpen(true)}
                onExportXLSX={handleXLSXExport}
              />
            );
          },
        }}
        columns={columns}
        loading={isLoading}
        autoHeight
        disableRowSelectionOnClick
        disableColumnMenu
        pageSizeOptions={[100]}
      />
      <Popover
        open={isFilterOpen}
        anchorEl={gridRef.current}
        onClose={() => setIsFilterOpen(false)}
        style={{
          marginTop: 40,
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Stack spacing={2} sx={{ padding: 2, width: 400, maxWidth: "100%" }}>
          <Typography variant="h6">Filters</Typography>
          <Autocomplete
            options={filterData ? filterData.userList : []}
            value={
              filterData
                ? filterData.userList.find(
                    (item) => item.id === queryOptions.userId
                  )
                : null
            }
            getOptionLabel={(option) => option.displayName}
            renderInput={(params) => <TextField {...params} label="Employee" />}
            onChange={(e, value) => {
              setQueryOptions((prev) => ({
                ...prev,
                userId: value ? value.id : undefined,
              }));
            }}
          />
          {/* <Autocomplete
            options={filterData ? filterData.channelList : []}
            value={
              filterData
                ? filterData.channelList.find(
                    (item) => item.id === queryOptions.channelId
                  )
                : null
            }
            getOptionLabel={(option) => option.teamName}
            renderInput={(params) => <TextField {...params} label="Team" />}
            onChange={(e, value) => {
              setQueryOptions((prev) => ({
                ...prev,
                channelId: value ? value.id : undefined,
              }));
            }}
          />
          <Autocomplete
            options={filterData ? filterData.contentList : []}
            value={
              filterData
                ? filterData.contentList.find(
                    (item) => item.id === queryOptions.contentId
                  )
                : null
            }
            getOptionLabel={(option) => option.contentName}
            renderInput={(params) => <TextField {...params} label="Content" />}
            onChange={(e, value) => {
              setQueryOptions((prev) => ({
                ...prev,
                contentId: value ? value.id : undefined,
              }));
            }}
          /> */}
        </Stack>
      </Popover>
    </Stack>
  );
};

export default EmployeesAnalytics;
