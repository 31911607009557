import type {} from "redux-thunk/extend-redux";

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "@mdxeditor/editor/style.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter } from "react-router-dom";

// import "moment/locale/lv";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "chartjs-adapter-moment";

import axios from "axios";

import { Provider } from "react-redux";
import { persistor, store } from "./store";

import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./config/applicationInsigts";

// MSAL imports
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from "@azure/msal-browser";
import { msalConfig } from "./config/auth";
import { init } from "@amplitude/analytics-browser";

import { Providers } from "@microsoft/mgt-element";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";

import CheckCircle from "./components/Icons/CheckCircle";
import ExclamationCircle from "./components/Icons/ExclamationCircle";
import QuestionCircle from "./components/Icons/QuestionCircle";
import { IconButton } from "@mui/material";
import Close from "./components/Icons/Close";

import { PersistGate } from "reduxjs-toolkit-persist/integration/react";
import FullScreenLoader from "./components/FullScreenLoader";
import moment from "moment";

export const msalInstance = new PublicClientApplication(msalConfig);

Providers.globalProvider = new Msal2Provider({
  publicClientApplication: msalInstance,
});

init(process.env.REACT_APP_AMPLITUDE_API_KEY as string, undefined, {
  plan: {
    source: "hub",
  },
});

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={<FullScreenLoader />}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <BrowserRouter>
          <AppInsightsContext.Provider value={reactPlugin}>
            <App pca={msalInstance} />
            <ToastContainer
              hideProgressBar
              position="top-center"
              toastStyle={{}}
              closeButton={(props) => (
                <IconButton onClick={props.closeToast} size="small">
                  <Close />
                </IconButton>
              )}
              icon={(props) => {
                switch (props.type) {
                  case "success":
                    return <CheckCircle />;
                  case "error":
                    return <ExclamationCircle color="#F47923" />;
                  case "info":
                    return <QuestionCircle />;
                  default:
                    return null;
                }
              }}
            />
          </AppInsightsContext.Provider>
        </BrowserRouter>
      </LocalizationProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
