import { useEffect, useState } from "react";

import {
  Stack,
  Typography,
  Chip,
  Avatar,
  CircularProgress,
} from "@mui/material";

import {
  COURSE_TRAINING_TYPE_LIST,
  CourseTrainingType,
} from "../constants/courseTrainingType";

import Calendar from "../components/Icons/Calendar";
import Hash from "../components/Icons/Hash";
import Clock from "../components/Icons/Clock";
import Target from "../components/Icons/Target";

import { COURSE_STATUS_LIST } from "../constants/courseStatus";

import moment from "moment";
import { ICourseAnalytics } from "../types/analytics";
import axios from "axios";
import { useAppSelector } from "../hooks/redux";

interface CourseDetailsProps {
  courseId: number;
  courseTrainingType: CourseTrainingType;
}

export default function CourseDetails({
  courseId,
  courseTrainingType,
}: CourseDetailsProps) {
  const languages = useAppSelector((state) => state.classifiers.languages);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ICourseAnalytics>();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get<ICourseAnalytics>("/CourseDistributions/GetCourseDistribution", {
        params: {
          id: courseId,
          courseTrainingType: courseTrainingType,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        style={{
          minHeight: 100,
        }}
      >
        <CircularProgress />
      </Stack>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Chip
          label={COURSE_TRAINING_TYPE_LIST[data.courseTrainingType].name}
          style={{
            backgroundColor:
              COURSE_TRAINING_TYPE_LIST[data.courseTrainingType].color,
          }}
        />
        <Typography>training</Typography>
      </Stack>
      <Stack direction="row">
        <Chip
          label={COURSE_STATUS_LIST[data.status].name}
          style={{
            backgroundColor: COURSE_STATUS_LIST[data.status].color,
          }}
        />
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <Calendar />
        <Typography>
          {moment(data.startDate).format("L")}
          {data.endDate && ` - ${moment(data.endDate).format("L")}`}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>Receive lessons on</Typography>
        <Stack direction="row" spacing={1}>
          {moment.weekdays().map((day: string) => {
            const isScheduled =
              // @ts-ignore
              data[day.toLowerCase()];

            if (isScheduled) {
              return (
                <Avatar
                  key={day}
                  sx={{
                    bgcolor: "text.primary",
                    width: 24,
                    height: 24,
                    fontSize: 12,
                  }}
                >
                  {day[0]}
                  {["T", "S"].includes(day[0]) ? day[1] : ""}
                </Avatar>
              );
            }
            return null;
          })}
        </Stack>
      </Stack>
      {[CourseTrainingType.Regular, CourseTrainingType.Mandatory].includes(
        data.courseTrainingType
      ) && (
        <Stack direction="row" spacing={1} alignItems="center">
          <Clock />
          <Typography>{data.lessonTime}</Typography>
        </Stack>
      )}
      {[CourseTrainingType.Regular, CourseTrainingType.Mandatory].includes(
        data.courseTrainingType
      ) && (
        <Stack direction="row" spacing={1} alignItems="center">
          <Hash />
          <Typography>{data.lessonCount} lessons</Typography>
        </Stack>
      )}
      {[CourseTrainingType.Instant, CourseTrainingType.Mandatory].includes(
        data.courseTrainingType
      ) && (
        <Stack direction="row" spacing={1} alignItems="center">
          <Target />
          <Typography>
            {data.correctAnswersCriteriaCount}/{data.lessonCount} successfully
            completed lesson goal
          </Typography>
        </Stack>
      )}
      <Stack direction="row" spacing={1} alignItems="center">
        <Chip label={languages[data.languageId].languageName} />
        <Typography>language</Typography>
      </Stack>
    </Stack>
  );
}
