import { useCallback, useEffect, useRef, useState } from "react";

import axios from "axios";

import {
  Autocomplete,
  Grid,
  Popover,
  Stack,
  TextField,
  Typography,
  Box,
  Tab,
  Breadcrumbs,
  Link,
} from "@mui/material";
import {
  IEmployeeCourseLessonsAnalytics,
  IEmployeesCourseLessons,
  IEmployeesKnowledgeFilters,
} from "../../../../../types/analytics";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import GridToolbar from "../../../../../components/Grid/Toolbar";
import xlsx, { IJsonSheet } from "json-as-xlsx";

import CustomPaper from "../../../../../components/CustomPaper";
import CourseDetails from "../../../../../components/CourseDetails";
import { useParams } from "react-router";
import { CourseTrainingType } from "../../../../../constants/courseTrainingType";
import ArchDiagram from "../../../../../components/Arch";
import { DIFFICULTY_LIST } from "../../../../../constants/difficulties";
import moment from "moment";
import Check from "../../../../../components/Icons/Check";
import Close from "../../../../../components/Icons/Close";

import { Link as RouterLink } from "react-router-dom";

import Helper from "../../../../../components/Helper";
import { EmployeesAnalyticsHelper } from "../../../../../constants/helpers";

import { TabContext, TabList, TabPanel } from "@mui/lab";

import CourseTrendsTab from "./TrendsTab";
import Chevron from "../../../../../components/Icons/Chevron";

interface IQueryOptions {
  userId?: number;
  channelId?: number;
  questionId?: number;
  topic?: string;
  difficulty?: number;
}

const CourseAnalytics: React.FunctionComponent<{}> = () => {
  const { teamId, courseTrainingType, courseId } = useParams<{
    teamId: string;
    courseTrainingType: string;
    courseId: string;
  }>();

  const [tab, setTab] = useState("1");

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IEmployeeCourseLessonsAnalytics>();
  const [filterData, setFilterData] = useState<IEmployeesKnowledgeFilters>();

  const gridRef = useRef(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [queryOptions, setQueryOptions] = useState<IQueryOptions>({});

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const handleXLSXExport = useCallback(() => {
    if (!data) {
      return;
    }

    const exportData: IJsonSheet[] = [
      {
        sheet: "Employees",
        columns: [
          {
            label: "Employee",
            value: "employeeName",
          },
          {
            label: "Team",
            value: "channelName",
          },
          {
            label: "Content",
            value: "contentName",
          },
          {
            label: "Sent lessons",
            value: "quizzesSent",
          },
          {
            label: "Completed lessons",
            value: "quizzesCompleted",
          },
          {
            label: "Completed lessons %",
            value: "quizzesCompletedPercent",
          },
          {
            label: "Correct lessons",
            value: "quizzesCorrect",
          },
          {
            label: "Correct lessons %",
            value: "quizzesCorrectPercent",
          },
        ],
        // @ts-ignore
        content: data.employeeList,
      },
    ];

    xlsx(exportData, {
      fileName: "employees-analytics",
    });
  }, [data]);

  useEffect(() => {
    axios
      .get<IEmployeesKnowledgeFilters>("/Alytics/GetEmployeeKnowledgeFilter")
      .then((res) => {
        setFilterData(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get<IEmployeeCourseLessonsAnalytics>("/Alytics/GetEmployeeKnowledge", {
        params: {
          courseTrainingType: courseTrainingType,
          courseId: courseId,
          ...queryOptions,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [queryOptions]);

  const columns: GridColDef<IEmployeesCourseLessons>[] = [
    {
      field: "employeeName",
      headerName: "Employee",
      sortable: false,
      minWidth: 200,
      flex: 1,
    },
    {
      field: "question",
      headerName: "Content",
      sortable: false,
      minWidth: 200,
      flex: 2,
    },
    {
      field: "topic",
      headerName: "Topic",
      sortable: false,
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return params.value || "-";
      },
    },
    {
      field: "difficulty",
      headerName: "Difficulty",
      sortable: false,
      minWidth: 140,
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        }

        return DIFFICULTY_LIST[params.row.difficulty].name;
      },
    },
    {
      field: "timeSent",
      headerName: "Time sent",
      sortable: false,
      minWidth: 200,
      renderCell: (params) => {
        return moment(params.value).format("L LT");
      },
    },
    {
      field: "timeAnswered",
      headerName: "Time completed",
      sortable: false,
      minWidth: 220,
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        }
        return moment(params.value).format("L LT");
      },
    },
    {
      field: "answerGiven",
      headerName: "Answer given",
      sortable: false,
      minWidth: 200,
      renderCell: (params) => {
        return params.value || "-";
      },
    },
    {
      field: "answerCorrect",
      headerName: "Expected answer",
      sortable: false,
      minWidth: 240,
      renderCell: (params) => {
        if (params.value === undefined) {
          return "-";
        }

        if (params.value) {
          return <Check color="#2e7d32" />;
        }
        return <Close color="#d32f2f" />;
      },
    },
  ];

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Stack direction="row" alignItems="center">
            <Typography component="h1" variant="h2">
              Team analytics
            </Typography>
            <Helper title={<EmployeesAnalyticsHelper />} />
          </Stack>
        </Grid>
      </Grid>
      <Breadcrumbs
        separator={
          <Chevron
            width={20}
            height={20}
            style={{
              transform: "rotate(90deg)",
            }}
          />
        }
      >
        <Link
          component={RouterLink}
          to={"/analytics/teams"}
          underline="none"
          color="inherit"
        >
          Teams
        </Link>
        <Link
          component={RouterLink}
          to={`/analytics/teams/${teamId}`}
          underline="none"
          color="inherit"
        >
          {data?.teamName}
        </Link>

        <Typography variant="inherit">{data?.courseName}</Typography>
      </Breadcrumbs>

      <TabContext value={tab}>
        {[CourseTrainingType.Instant, CourseTrainingType.Regular].includes(
          parseInt(courseTrainingType as string)
        ) && (
          <Stack direction="row">
            <TabList onChange={handleTabChange}>
              <Tab label="Lessons" value="1" />
              <Tab label="Trends" value="2" />
            </TabList>
          </Stack>
        )}

        <TabPanel value="1">
          <Stack spacing={2}>
            <Stack spacing={2}>
              <Box>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <Typography variant="h3">Training details</Typography>
                    <CustomPaper sx={{ padding: 2 }}>
                      <CourseDetails
                        courseId={parseInt(courseId as string)}
                        courseTrainingType={
                          courseTrainingType as unknown as CourseTrainingType
                        }
                      />
                    </CustomPaper>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                    }}
                  >
                    <Stack flex={1}>
                      <Typography variant="h3">
                        Successfully completed lesson average
                      </Typography>
                      <CustomPaper
                        sx={{
                          position: "relative",
                          minHeight: 200,

                          flex: 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ArchDiagram
                          primaryText="12/14"
                          color="default"
                          // color={
                          //   courseDetails?.courseTrainingType ===
                          //   CourseTrainingType.Regular
                          //     ? "default"
                          //     : false
                          //     ? // courseDetails?.lessonsCorrect  >= courseDetails?.correctAnswersCriteriaCount
                          //       "success"
                          //     : "error"
                          // }
                          secondaryText="Goal: 10/14"
                          // secondaryText={
                          //   courseDetails?.courseTrainingType !==
                          //   CourseTrainingType.Regular
                          //     ? courseDetails?.courseTrainingType ===
                          //       CourseTrainingType.Mandatory
                          //       ? `Goal: ${courseDetails?.correctAnswersCriteria}%`
                          //       : `Goal: ${courseDetails?.correctAnswersCriteriaCount}/${courseDetails?.lessonCount}`
                          //     : undefined
                          // }
                          completion={60}
                          // completion={
                          //   (courseDetails?.lessonsCorrect /
                          //     courseDetails?.lessonsAnswered) *
                          //   100
                          // }
                        />
                      </CustomPaper>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>

              <Stack>
                <Typography variant="h3">Lessons</Typography>
                <DataGrid
                  ref={gridRef}
                  disableColumnFilter
                  getRowId={(row) => row.quizId}
                  rows={data?.employeeKnowledge || []}
                  rowCount={3}
                  components={{
                    Toolbar: () => {
                      return (
                        <GridToolbar
                          filterCount={
                            Object.values(queryOptions).filter((item) => item)
                              .length
                          }
                          toggleFilter={() => setIsFilterOpen(true)}
                          onExportXLSX={handleXLSXExport}
                        />
                      );
                    },
                  }}
                  columns={columns}
                  loading={isLoading}
                  autoHeight
                  disableRowSelectionOnClick
                  disableColumnMenu
                  pageSizeOptions={[100]}
                />
              </Stack>
            </Stack>

            <Popover
              open={isFilterOpen}
              anchorEl={gridRef.current}
              onClose={() => setIsFilterOpen(false)}
              style={{
                marginTop: 40,
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Stack
                spacing={2}
                sx={{ padding: 2, width: 400, maxWidth: "100%" }}
              >
                <Typography variant="h6">Filters</Typography>
                <Autocomplete
                  options={filterData ? filterData.userList : []}
                  value={
                    filterData
                      ? filterData.userList.find(
                          (item) => item.id === queryOptions.userId
                        )
                      : null
                  }
                  getOptionLabel={(option) => option.displayName}
                  renderInput={(params) => (
                    <TextField {...params} label="Employee" />
                  )}
                  onChange={(e, value) => {
                    setQueryOptions((prev) => ({
                      ...prev,
                      userId: value ? value.id : undefined,
                    }));
                  }}
                />
                <Autocomplete
                  options={filterData ? filterData.questionList : []}
                  value={
                    filterData
                      ? filterData.questionList.find(
                          (item) => item.id === queryOptions.questionId
                        )
                      : null
                  }
                  getOptionLabel={(option) => option.questionText}
                  renderInput={(params) => (
                    <TextField {...params} label="Content" />
                  )}
                  onChange={(e, value) => {
                    setQueryOptions((prev) => ({
                      ...prev,
                      questionId: value ? value.id : undefined,
                    }));
                  }}
                />
                <Autocomplete
                  options={filterData ? filterData.topicList : []}
                  value={
                    filterData
                      ? filterData.topicList.find(
                          (item) => item.topicText === queryOptions.topic
                        )
                      : null
                  }
                  getOptionLabel={(option) => option.topicText}
                  renderInput={(params) => (
                    <TextField {...params} label="Topic" />
                  )}
                  onChange={(e, value) => {
                    setQueryOptions((prev) => ({
                      ...prev,
                      topic: value ? value.topicText : undefined,
                    }));
                  }}
                />
                <Autocomplete
                  options={filterData ? filterData.difficultyList : []}
                  value={
                    filterData
                      ? filterData.difficultyList.find(
                          (item) =>
                            item.difficultyValue === queryOptions.difficulty
                        )
                      : null
                  }
                  getOptionLabel={(option) => option.difficultyText}
                  renderInput={(params) => (
                    <TextField {...params} label="Difficulty" />
                  )}
                  onChange={(e, value) => {
                    setQueryOptions((prev) => ({
                      ...prev,
                      difficulty: value ? value.difficultyValue : undefined,
                    }));
                  }}
                />
              </Stack>
            </Popover>
          </Stack>
        </TabPanel>
        <TabPanel value="2">
          <CourseTrendsTab />
        </TabPanel>
      </TabContext>
    </Stack>
  );
};

export default CourseAnalytics;
