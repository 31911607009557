import { TRoute, RouteType } from "../types/route";

import Leaderboard from "../views/client/Leaderboard";

import Courses from "../views/client/Courses";
import Course from "../views/client/Courses/Course";

import ChartBar from "../components/Icons/ChartBar";
import Books from "../components/Icons/Books";

// INFO: path /docs is reserved route for documentation and handled as static content

export const routes: TRoute[] = [
  {
    type: RouteType.Menu,
    path: "/courses",
    element: Courses,
    name: "Courses",
    icon: <Books />,
  },
  {
    type: RouteType.Basic,
    path: "/courses/:courseId/:courseTrainingType",
    element: Course,
  },
  {
    type: RouteType.Menu,
    path: "/leaderboard",
    element: Leaderboard,
    name: "Leaderboard",
    icon: <ChartBar />,
  },
];
